<template>
    <div style="background-color: #fcfeff;">
        <img src="/images/product/MetaTlr.jpg" style="align-self: center; width: 80%;height: auto;margin-bottom: 10px ;" >
    </div>
</template>

<script>
import { ref } from 'vue';
export default {
    components: {
    },
    setup() {

        return {

        }
    },
    methods: {
    }
}
</script>

<style scoped>

</style>