import { createRouter, createWebHistory } from 'vue-router'
import About from '../views/About.vue'
import Introduction from '../views/Products/Introduction.vue'
import MetaAmp5 from '../views/Products/MetaAMP5.vue'
import MetaAmp33 from '../views/Products/MetaAMP33.vue'
import MetaCono from '../views/Products/MetaCono.vue'
import MetaPepOcean1 from '@/views/Products/MetaPepOcean1.vue'
import MetaAMP12 from '@/views/Products/MetaAMP12.vue'
import Technology from '@/views/Technology.vue'
import Media from '@/views/Media.vue'
import MetaTlr from '@/views/Products/MetaTlr.vue'

const routes = [
  {
    path: '/',
    name: 'About',
    component: About,
  },
  {
    path: '/technology',
    name: 'Technology',
    component: Technology
  },
  {
    path: '/products',
    name: "Products",
    children: [
      {
        path: '/products/introduction',
        name: 'ProductsIntroduction',
        component: Introduction,
      },
      {
        path: '/products/metaamp5',
        name: 'ProductsMetaAMP5',
        component: MetaAmp5,
      },
      {
        path: '/products/metaamp33',
        name: 'ProductsMetaAMP33',
        component: MetaAmp33,
      },
      {
        path: '/products/metacono',
        name: 'ProductsMetaCono',
        component: MetaCono,
      },
      {
        path: '/products/metapepocean1',
        name: 'ProductsMetaPepOcean1',
        component: MetaPepOcean1,
      },
      {
        path: '/products/metatlr',
        name: 'MetaTlr',
        component: MetaTlr,
      },
      {
        path: '/products/metaamp12',
        name: 'ProductsMetaAMP12',
        component: MetaAMP12,
      },
    ],
  },
  {
    path: '/media',
    name: 'Media',
    component: Media
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      var top = document.getElementById(to.hash.replace('#', '')).offsetTop;
      if (to.name == 'Technology') {
        top = top - 100;
      }
      return window.scrollTo({
        top: top,
        behavior: 'smooth',
      })
    } else {
      return window.scrollTo({
        top: 0,
      })
    }
  },
  routes
})

export default router
